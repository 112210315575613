import { defineStore } from 'pinia'
import { type ISite, IHM_SVC } from "../api/ihm"


interface ISiteState {
    sites: ISite[],
    site: ISite | null,
    loading: boolean
}

export const useSitesStore = defineStore('sites', {
    state: (): ISiteState => ({
        sites: [],
        site: null,
        loading: false
    }),
    getters: {
        getSiteBySlug(state) {
            return (slug: string) => state.sites.find((site) => site.slug === slug)
        }
    },
    actions: {
        async fetchSites(sites: ISite[]) {
            this.loading = true;
            try {
                this.sites = await IHM_SVC.getSites();
                if (this.sites.length > 0) {
                    this.site = this.sites[0];
                }
            } catch (error) {
                console.error(error)
            } finally {
                if (!Array.isArray(this.sites)) {
                    this.sites = [];
                }
                this.loading = false
            }
        }
    }
})