import axios, { type AxiosInstance } from "axios";

const runtimeConfig = useRuntimeConfig()

const ihmSvcLocation: string = runtimeConfig.public.apiIHM;

let httpClient: AxiosInstance;

let basePath: string = "/ihm-api"

if (window.location.port == "") {
    httpClient = axios.create({
        baseURL: "http://" + window.location.host + basePath,
        timeout: 15000,
        withCredentials: false,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
        },
    });
} else {
    httpClient = axios.create({
        baseURL: ihmSvcLocation + basePath,
        withCredentials: false,
        timeout: 15000,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("user-token"),
        },
    });
}

export interface ISite {
    id: string
    name: string,
    slug: string,
    host: string,
    map_center: number[],
    events_use_case: boolean,
    tolls_use_case: boolean 
    bus_use_case: boolean
}
export interface IInfo {
    version_id: string,
    date: string
}


export const IHM_SVC = {
    async getSites(): Promise<ISite[]> {
        let url = `/sites`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getInfos(): Promise<IInfo> {
        let url = `/infos`;
        let response = await httpClient.get(url);
        return response.data;
    }
}
